import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import ExclamationCircled from 'assets/icons/exclamation-circled';
import Checklist from 'components/appOnboardingExperience/Checklist';
import OnboardingButton from 'components/appOnboardingExperience/OnboardingButton';
import { OnboardingCompletedNotification } from 'components/appOnboardingExperience/OnboardingCompletedNotification';
import { Header, Home, Nav } from 'components/dashboard';
import CanvasBanner from 'components/dashboard/CanvasBanner';
import { useChecklistData } from 'components/dashboard/Checklist';
import ExtInstallationModal from 'components/dashboard/ExtInstallationModal';
import StudioSidebarBanner from 'components/dashboard/StudioSidebarBanner';
import StudioTopBanner from 'components/dashboard/StudioTopBanner';
import Head from 'components/elements/Head';
import Loading from 'components/elements/Loading';
import LoadingSkeleton from 'components/elements/LoadingSkeleton';
import Meta from 'components/elements/Meta';
import { importSDK } from 'components/experience/readers/newsdk';
import { AutoScrollButton } from 'components/features/AutoScrollButton';
import { GamificationSetGoalPrompExperimentModal } from 'components/gamification/GamificationSetGoalPromptExperiment';
import { FolderModal, FolderTree, Items as Library, ShareModal } from 'components/library';
import EnabledIntegrations from 'components/library/import/EnabledIntegrations';
import Pagination from 'components/library/items/components/Pagination';
import usePaginationState from 'components/library/items/hooks/usePaginationState';
import CSATPopup from 'components/newListeningExperience/popups/CSATPopup';
import ReferralSideBanner from 'components/referrals/ReferralSideBanner';
import { VoiceCloning } from 'components/voice-cloning';
import { ErrorSource } from 'constants/errors';
import { WebAppListeningExperienceOverhaulVariant } from 'constants/featureDefinitions';
import { useAutoScroll } from 'hooks/useAutoScroll';
import { useCustomCompareEffect } from 'hooks/useCustomCompareEffect';
import { FeatureNameEnum, getFeatureVariant, isFeatureVariantReady, logVariant, useFeatureFlag, useFeatureVariant } from 'hooks/useFeatureFlags';
import { useMeasureFromNavigationStart } from 'hooks/useMeasureFromNavigationStart';
import useMediaQuery from 'hooks/useMediaQuery';
import { useNavigate } from 'hooks/useNavigate';
import useResizeObserver from 'hooks/useResizeObserver';
import { useStudioBanner } from 'hooks/useStudioBanner';
import { useTranslation } from 'hooks/useTypedTranslation';
import {
  InstantListening,
  IRecord,
  isInstantListeningV1,
  isInstantListeningV1Loaded,
  isInstantListeningV2Loaded,
  ItemActionType,
  IUser,
  ViewType
} from 'interfaces';
import { logError } from 'lib/observability';
import { CDN_PSPDFKIT_BASE_URL } from 'lib/pdf/constants';
import * as speechify from 'lib/speechify';
import { isEqual, pick } from 'lodash';
import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';
import { preloadNewListeningExperience } from 'modules/listening/utils/preload';
import { CSAT_SHOWN_STORAGE_KEY } from 'modules/sideBanner/stores/csatActions';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { RootState, useDispatch, useSelector } from 'store';
import { actions as authActions } from 'store/auth';
import { isUserNew } from 'store/auth/helpers';
import { actions as itemActions } from 'store/item';
import { actions as libraryActions } from 'store/library';
import { actions as mainPageActions } from 'store/mainPage';
import { actions as readerActions } from 'store/reader';
import { actions as sidebarActions } from 'store/sidebar';
import { twMerge } from 'tailwind-merge';
import { useLocalStorage } from 'usehooks-ts';
import { carryParams } from 'utils';
import { logSegmentEvent, segmentIdentifyByUserId } from 'utils/analytics';
import { getCustomAccountSetting } from 'utils/baseAccountSettings';
import { useDarkMode } from 'utils/darkMode';
import { checkExtensionInstalled, getExtensionId } from 'utils/extension';
import { useIntercomDefault } from 'utils/intercom';
import { replaceUrlWithoutAnyEffect } from 'utils/navigation';
import { preloadPSPDFKIT } from 'utils/pspdfkit';
import withAuth from 'utils/withAuth';

import { PlayPauseButton } from '@speechifyinc/multiplatform-sdk';

import DragAndDrop from '../components/dashboard/DragAndDrop';
import { useFeedback } from '../components/experience/readers/newsdk/controls/utils/useFeedbackCsat';
import { isBook, isClassic } from '../components/experience/readers/newsdk/ReadingInfo';
import usePreferenceVariable from '../hooks/usePreferenceVariable';

const importSidebar = () =>
  import(
    /* webpackChunkName: "components/sidebar/Sidebar" */
    'components/sidebar/Sidebar'
  );

const Sidebar = dynamic(importSidebar, {
  ssr: false,
  loading: () => <></>
});

const importSDKBookReader = () =>
  import(
    /* webpackChunkName: "components/experience/readers/newsdk/book/SDKBookReader" */
    'components/experience/readers/newsdk/book/SDKBookReader'
  );
const SDKBookReader = dynamic(importSDKBookReader, { ssr: false, loading: () => <LoadingSkeleton /> });

const SDKClassicReader = dynamic(() => import('components/experience/readers/newsdk/classic/SDKClassicReader'), {
  ssr: false,
  loading: () => <LoadingSkeleton />
});

const importListeningExperienceV2 = () =>
  import(
    /* webpackChunkName: "components/listening/ListeningExperienceV2" */
    'modules/listening/components/ListeningExperienceV2'
  );

const ListeningExperienceV2 = dynamic(importListeningExperienceV2, { ssr: false, loading: () => <LoadingSkeleton /> });

const preload = async () => {
  [
    /* Preloading pspdfkit dependencies: start */
    '/pspdfkit-lib/pspdfkit-a40f15a37f0211b1.wasm.js',
    '/pspdfkit-lib/pspdfkit-d65f8c86d3c3e05c.wasm'
    /* Preloading pspdfkit dependencies: end */
  ].forEach(asset => fetch(asset));

  const listeningOverhaulVariant = await getFeatureVariant(FeatureNameEnum.WEB_APP_LISTENING_EXPERIENCE_OVERHAUL);
  if (listeningOverhaulVariant === WebAppListeningExperienceOverhaulVariant.CONTROL) {
    preloadPSPDFKIT({
      baseUrl: CDN_PSPDFKIT_BASE_URL
    });
    importSDK().then(module => {
      module.getAllVoices(); // preload CVL voices
    });

    importSDKBookReader();
    importSidebar();
  } else {
    preloadNewListeningExperience();
    importListeningExperienceV2();
  }
};

const is24HoursAgo = (date: Date): boolean | null => {
  if (!date) {
    return null;
  }
  const hours = 1000 * 60 * 60 * 24;
  const hoursago = Date.now() - hours;

  return date.valueOf() < hoursago;
};

const DashboardPage = () => {
  const dispatch = useDispatch();

  const measureFromNavigationStart = useMeasureFromNavigationStart();
  const navigate = useNavigate();

  const router = useRouter();
  const { t } = useTranslation('common');

  const { ref, width = 186 } = useResizeObserver<HTMLDivElement>();

  const {
    currentUploadingId,
    isInited,
    isLoading,
    isSyncing,
    itemCount,
    folders: stateFolders,
    items: stateItems
  } = useSelector((state: RootState) => state.library);

  const currentPage = useSelector(state => state.library.currentPage) || 1;
  const user = useSelector(state => state.auth.user);

  const { activeDropTargetId, instantListening, isDragging, newFolderParentId, newlyImportedItemId, mobileAction } = useSelector(
    (state: RootState) => state.mainPage
  );

  // state
  const [folderId, setFolderId] = useState<string | undefined>();
  const [folders, setFolders] = useState<IRecord[]>([]);
  const [isNewUser, setIsNewUser] = useState(null);
  const [items, setItems] = useState<IRecord[]>([]);
  const [shareProps, setShareProps] = useState<{ loading?: boolean; url?: string }>({});
  const [showChecklist, setShowChecklist] = useState(false);
  const [showExtInstallationModal, setShowExtInstallationModal] = useState(false);
  const [updatePaymentLoading, setUpdatePaymentLoading] = useState(false);
  const [viewType, setViewType] = useState<ViewType>(ViewType.GRID);
  const [trigger, setTrigger] = useState<string>('');

  const onboardingCompleted = useSelector((state: RootState) => state.onboarding.onboardingCompleted);
  const onboardingCompletedNotificationDismissed = useSelector((state: RootState) => state.onboarding.onboardingCompletedNotificationDismissed);

  // feature flags
  const { variant: webAppZeroStateVariant, isLoading: webAppZeroStateIsLoading } = useFeatureFlag(FeatureNameEnum.WEB_APP_ZERO_STATE);
  const { variant: newEmmaModelVariant, isLoading: newEmmaModelVariantLoading } = useFeatureFlag(FeatureNameEnum.NEW_EMMA_MODEL);
  const { variant: ocrVariant, isLoading: ocrVariantLoading } = useFeatureFlag(FeatureNameEnum.OCR);
  const { variant: pdfUpsellVariant, isLoading: pdfUpsellVariantLoading } = useFeatureFlag(FeatureNameEnum.PDF_UPSELL);
  const { variant: showPaginationVariant, isLoading: showPaginationVariantLoading } = useFeatureFlag(FeatureNameEnum.WEB_SHOW_PAGINATION);

  const { revampedChecklistItemsList } = useChecklistData();

  const isPaginationEnabled = showPaginationVariant === 'enabled';

  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);
  const isChecklistCompleted = revampedChecklistItemsList.every(item => item.done);
  // @ts-expect-error TS(2769): No overload matches this call.
  const userCreated24HoursAgo = is24HoursAgo(new Date(user?.metadata?.creationTime));
  const [canvasBannerClosed, setCanvasBannerClosed] = useLocalStorage('canvas_lms_banner_closed', false);

  // @ts-expect-error TS(2345): Argument of type 'boolean | null' is not assignabl... Remove this comment to see the full error message
  const { showStudioBanner, handleStudioBannerClick, closeStudioBanner } = useStudioBanner(isChecklistCompleted, userCreated24HoursAgo);

  useIntercomDefault(currentUser);

  useEffect(() => {
    if (!isChecklistCompleted && userCreated24HoursAgo) {
      dispatch(libraryActions.maximizeChecklist(false));
    }
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecklistCompleted, userCreated24HoursAgo]);

  // logging feature flags
  useEffect(() => {
    if (!ocrVariantLoading) {
      logVariant('ocr', ocrVariant);
    }

    if (!pdfUpsellVariantLoading) {
      logVariant('pdfUpsell', pdfUpsellVariant);
    }
    // ESLint: React Hook useEffect has missing dependencies: 'ocrVariant' and 'pdfUpsellVariant'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrVariantLoading, pdfUpsellVariantLoading]);

  useEffect(() => {
    if (!showPaginationVariantLoading) {
      logVariant('webShowPagination', showPaginationVariant);
    }
  }, [showPaginationVariant, showPaginationVariantLoading]);

  useEffect(() => {
    if (!webAppZeroStateIsLoading) {
      if (webAppZeroStateVariant === 'enabled') {
        logVariant('webAppZeroState', webAppZeroStateVariant);
      }
    }
  }, [webAppZeroStateVariant, webAppZeroStateIsLoading]);

  useEffect(() => {
    let shouldUpdate = true;

    if (speechify.auth.currentUser?.uid) {
      getCustomAccountSetting('clickedStartListening')
        .then(setting => {
          if (shouldUpdate) setShowChecklist(setting !== 'true');
        })
        .catch(e => {
          if (shouldUpdate) console.warn('Could not get account settings', e);
        });
    }

    return () => {
      shouldUpdate = false;
    };
    // ESLint: React Hook useEffect has an unnecessary dependency: 'speechify.auth.currentUser.uid'. Either exclude it or remove the dependency array. Outer scope values like 'speechify.auth.currentUser.uid' aren't valid dependencies because mutating them doesn't re-render the component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speechify.auth.currentUser?.uid]);

  useEffect(() => {
    dispatch(readerActions.clearReaderState());

    return () => {
      libraryActions.unsubscribe();
    };
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCustomCompareEffect(
    () => {
      const currentFolderItems = (stateItems || []).filter(item => item.parentFolderId === (folderId || null));

      setFolders(stateFolders || []);
      setItems(currentFolderItems);
    },
    [[...(stateFolders || []), ...(stateItems || [])], folderId],
    (prevDeps: [IRecord[], string | undefined], nextDeps: [IRecord[], string | undefined]) => {
      const equalityProps = [
        'createdAt',
        'coverImagePath',
        'id',
        'parentFolderId',
        'progressPercent',
        'recordType',
        'sourceURL',
        'status',
        'title',
        'type',
        'updatedAt',
        'wordCount'
      ];

      if (prevDeps[1] !== nextDeps[1]) {
        return false;
      }

      if (prevDeps[0].length !== nextDeps[0].length) {
        return false;
      }

      for (let i = 0; i < prevDeps[0].length; i++) {
        const prevItem = pick(prevDeps[0][i], equalityProps);
        const nextItem = pick(nextDeps[0][i], equalityProps);

        if (!isEqual(prevItem, nextItem)) {
          return false;
        }
      }

      return true;
    }
  );

  const init = async () => {
    const isExtensionInstalled = await checkExtensionInstalled();
    logSegmentEvent('extension_site_visited', { isExtensionInstalled });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!newEmmaModelVariantLoading) {
      logVariant('newEmmaModel', newEmmaModelVariant);
    }
  }, [newEmmaModelVariant, newEmmaModelVariantLoading]);

  useEffect(() => {
    setFolderId(router.query.folder?.toString());
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    window['folder'] = router.query.folder?.toString();
  }, [router.query.folder]);

  useEffect(() => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (user.uid) {
      // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
      setIsNewUser(isUserNew(user));
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      segmentIdentifyByUserId(user.uid);
    }
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    // ESLint: React Hook useEffect has a missing dependency: 'user'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uid]);

  useEffect(() => {
    setViewType((router.query.view?.toString() as ViewType) || ViewType.GRID);
  }, [router.query.view]);

  useEffect(() => {
    if (router.query.importModal) {
      // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      const uppy = window['uppy'];

      if (uppy) {
        setTimeout(() => {
          navigate('/');
          uppy?.getPlugin('Dashboard').openModal();
        }, 2000);
      }
    }
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    // ESLint: React Hook useEffect has a missing dependency: 'navigate'. Either include it or remove the dependency array. & React Hook useEffect has a complex expression in the dependency array. Extract it to a separate variable so it can be statically checked.
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [window['uppy'], router.query.importModal]);

  const handleFolderCancel = () => {
    dispatch(mainPageActions.setNewFolderParentId(undefined));
  };

  const handleFolderSubmit = (title: string) => {
    dispatch(libraryActions.addFolder({ title, parentFolderId: newFolderParentId }));
    dispatch(mainPageActions.setNewFolderParentId(undefined));
  };

  // ESLint: 'location' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleItemAction = async (item: IRecord, actionType: ItemActionType, location: string = 'library') => {
    switch (actionType) {
      case ItemActionType.NewFolder:
        dispatch(mainPageActions.setNewFolderParentId(item.id));
        break;

      case ItemActionType.Archive:
      case ItemActionType.ArchiveFolder:
      case ItemActionType.Delete:
      case ItemActionType.DeleteFolder:
        dispatch(itemActions.setConfirmItem({ item, action: actionType }));
        break;

      case ItemActionType.Move:
        dispatch(itemActions.setItemToMove(item));
        break;

      case ItemActionType.Rename:
        dispatch(itemActions.setItemToRename({ item }));
        break;

      case ItemActionType.Share: {
        setShareProps({ loading: true });

        const link = await speechify.shareItem(item.id);

        setShareProps({ loading: false, url: link });
        logSegmentEvent('web_app_library_share_document', { link });

        break;
      }

      case ItemActionType.DownloadMp3: {
        dispatch(itemActions.setItemToDownload(item));
        logSegmentEvent('web_app_library_download_menu_clicked');
        break;
      }
    }
  };

  const handleMobileNewSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const action = e.target.value;

    dispatch(mainPageActions.setMobileAction(action));

    setTimeout(() => {
      e.target.selectedIndex = 0;
      dispatch(mainPageActions.setMobileAction(''));
    }, 1500);
  };

  const handleShareModalOk = () => {
    setShareProps({});
  };

  const {
    renderStartRange,
    renderEndRange,
    page: currentPaginationPage,
    pageCount,
    setCurrentPage: setPaginationCurrentPage,
    handlePageNumberChange
  } = usePaginationState(items, folderId, itemCount, isPaginationEnabled);

  useEffect(() => {
    if (router.query.trigger) {
      setTrigger(router.query.trigger as string);
    }
  }, [router.query.trigger]);

  useEffect(() => {
    switch (trigger) {
      case 'upload':
        // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
        // ESLint: Unexpected lexical declaration in case block
        // eslint-disable-next-line no-case-declarations
        const dashboard = window['uppy']?.getPlugin('Dashboard');
        if (dashboard && !dashboard.isModalOpen()) {
          dashboard.openModal();
          if (router.query.type === 'google-drive') {
            setTimeout(() => {
              (document.querySelector('[data-cy="GoogleDrive"]') as HTMLButtonElement)?.click();
            }, 500);
          }
        }
        break;
      case 'weblink':
        dispatch(mainPageActions.setMobileAction('web-link'));
        break;
      case 'text':
        dispatch(mainPageActions.setMobileAction('text-document'));
        break;
    }
    // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
    // ESLint: React Hook useEffect has missing dependencies: 'dispatch' and 'router.query.type'. Either include them or remove the dependency array. & React Hook useEffect has a complex expression in the dependency array. Extract it to a separate variable so it can be statically checked.
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [trigger, window['uppy']]);

  useEffect(() => {
    navigate(carryParams('/', { page: currentPage }), undefined, { shallow: true });
    setPaginationCurrentPage(currentPage);
    // ESLint: React Hook useEffect has missing dependencies: 'navigate' and 'setPaginationCurrentPage'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (user.subscription.renewalStatus?.name === 'MISSED_PAYMENT') logSegmentEvent('web_app_update_payments_banner_seen');
    // @ts-expect-error TS(2531): Object is possibly 'null'.
  }, [user.subscription.renewalStatus?.name]);

  const openPaymentPage = async () => {
    setUpdatePaymentLoading(true);
    logSegmentEvent('web_app_update_payment_clicked');

    // first clear any stale billing dashboard url value
    dispatch(authActions.clearBillingDashboardUrl());

    // then get the billing dashboard url
    try {
      const extensionId = await getExtensionId();
      const billingDashboardUrl = await speechify.getBillingDashboardUrl(new speechify.BillingDashboardOptions(extensionId));
      dispatch(authActions.setBillingDashboardUrl(billingDashboardUrl));
    } catch (error) {
      logError(error as Error, ErrorSource.SUBSCRIPTION);
    }

    logSegmentEvent('web_app_open_billing_dashboard', { url: user?.billingDashboardUrl });
    setUpdatePaymentLoading(false);

    const billingDashboardUrl = currentUser?.billingDashboardUrl;
    const _billingDashboardUrlModified = speechify.isStripe(currentUser) ? `${billingDashboardUrl}/payment-methods` : billingDashboardUrl;

    // @ts-expect-error TS(2345): Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
    return billingDashboardUrl ? window.open(_billingDashboardUrlModified, '_blank') : window.open(`/settings?tab=Subscription`, '_blank');
  };

  const [showingFeedback, setShowingFeedback] = useState(false);

  // @ts-expect-error TS(2345): Argument of type 'boolean | null' is not assignabl... Remove this comment to see the full error message
  useDarkMode(instantListening && isInstantListeningV1Loaded(instantListening));

  useEffect(() => {
    if (instantListening && isInstantListeningV1Loaded(instantListening)) {
      dispatch(sidebarActions.setIsLoading(false));
    }

    return () => {
      dispatch(sidebarActions.setIsLoading(true));
    };
    // ESLint: React Hook useEffect has a missing dependency: 'dispatch'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instantListening]);

  // preload sdk and pspdfkit
  useEffect(() => {
    if (typeof window !== 'undefined') preload();
  }, []);

  useEffect(() => {
    if (isInited) {
      measureFromNavigationStart('library_ready');
    }
    // ESLint: React Hook useEffect has a missing dependency: 'measureFromNavigationStart'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInited]);

  const showCanvasBanner = !showingFeedback && (isChecklistCompleted || userCreated24HoursAgo);

  return (
    <>
      <Head title={t('Library')} />
      <Meta />

      {instantListening ? (
        <InstantListeningRenderer instantListening={instantListening} newlyImportedItemId={newlyImportedItemId} />
      ) : (
        <>
          <VoiceCloning trigger="upsell-modal" />
          <StudioTopBanner isOpen={showStudioBanner === 'top'} onClick={handleStudioBannerClick} onClose={closeStudioBanner} />
          <DragAndDrop viewType={viewType}>
            <div className="min-h-screen flex-col bg-pageBackground">
              <main className={twMerge('mx-auto flex-grow pb-10')}>
                {
                  // @ts-expect-error TS(2531): Object is possibly 'null'.
                  user.subscription.renewalStatus?.name == 'MISSED_PAYMENT' && (
                    <div className="fixed bottom-0 z-2000 ml-[-5px] flex h-40 w-full justify-between bg-yellow-400 px-6 font-ABCDiatype text-base text-glass-800 sm:ml-[-24px] md:h-20 lg:h-12">
                      <div className="flex p-3">
                        <ExclamationCircled width="24px" height="24px" style={{ marginRight: '10px' }}></ExclamationCircled>
                        <div>
                          {' '}
                          <b>{t('Don’t lose your premium access!')} </b> &nbsp;{' '}
                          {t('We are unable to charge you for your Speechify subscription. Please fix your payment information.')}
                        </div>
                      </div>
                      <button
                        className="m-2 flex h-8 w-32 items-center justify-center rounded-lg bg-glass-0 font-ABCDiatype text-sm font-bold text-glass-800"
                        onClick={openPaymentPage}
                      >
                        {updatePaymentLoading ? <Loading size={20} /> : t('Update Payment')}
                      </button>
                    </div>
                  )
                }

                <div className="md:grid md:grid-cols-[fit-content(280px)_minmax(0,1fr)] md:gap-x-5">
                  <div className="hidden pb-6 md:block" style={{ width }}></div>
                  <aside
                    className="fixed z-40 hidden select-none py-4 md:block"
                    ref={ref}
                    style={{ minWidth: '250px', maxWidth: '364px', width: 'max-content' }}
                  >
                    <div
                      className="relative flex flex-col pl-4 font-ABCDiatype"
                      style={{ height: `calc(100vh - ${showStudioBanner === 'top' ? '56px' : '0px'})` }}
                    >
                      <div className="flex items-center px-2 pt-2 pb-6 xl:w-64">
                        <Home />
                      </div>

                      <EnabledIntegrations>
                        <Nav mobileAction={mobileAction} />
                      </EnabledIntegrations>

                      <div className="mb-2 flex-grow overflow-y-auto overflow-x-clip">
                        <FolderTree activeDropTargetId={activeDropTargetId} folders={folders} isDragging={isDragging} onFolderAction={handleItemAction} />
                        <ReferralSideBanner />
                      </div>

                      <FeedbackRenderer setShowingFeedback={show => setShowingFeedback(show)} />
                      {showStudioBanner ? (
                        <StudioSidebarBanner
                          isOpen={showStudioBanner === 'sidebar'}
                          onClose={closeStudioBanner}
                          onClick={handleStudioBannerClick}
                          // @ts-expect-error TS(2322): Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
                          positionUp={!showingFeedback && isNewUser && showChecklist && !isChecklistCompleted && userCreated24HoursAgo}
                        />
                      ) : showCanvasBanner ? (
                        <CanvasBanner
                          closed={canvasBannerClosed}
                          onClose={() => setCanvasBannerClosed(true)}
                          // @ts-expect-error TS(2322): Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
                          positionUp={!showingFeedback && isNewUser && showChecklist && !isChecklistCompleted && userCreated24HoursAgo}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </aside>

                  <div>
                    <Header />
                    <div>
                      <div className="md:hidden">
                        <label htmlFor="import" className="sr-only">
                          {t('New')}
                        </label>
                        <select id="import" className="block w-full rounded-md border-gray-200 " defaultValue={''} onChange={handleMobileNewSelectChange}>
                          <option value="">{t('New')}</option>
                          <option value="web-link">{t('Web Link')}</option>
                          <option value="text-document">{t('Text Document')}</option>
                          <option value="pdf-document">{t('PDF Document')}</option>
                        </select>
                      </div>
                    </div>

                    <Library
                      defaultViewType={ViewType.LIST}
                      displayRangeStart={renderStartRange}
                      displayRangeEnd={renderEndRange}
                      folders={folders}
                      isLoading={isLoading}
                      isSyncing={isSyncing}
                      items={items}
                      onItemAction={handleItemAction}
                      processingId={currentUploadingId}
                    />

                    {pageCount > 1 && (
                      <div className="flex items-center justify-center p-6">
                        {isPaginationEnabled && (
                          <Pagination
                            currentPage={currentPaginationPage}
                            onPageChange={pageNumber => {
                              handlePageNumberChange(pageNumber);
                              dispatch(libraryActions.setCurrentPage(pageNumber));
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            itemCount={itemCount >= 500 ? 500 : itemCount}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {!onboardingCompleted && isNewUser && (
                  <>
                    <Checklist />
                    <OnboardingButton />
                  </>
                )}

                {onboardingCompleted && !onboardingCompletedNotificationDismissed && <OnboardingCompletedNotification />}
              </main>
            </div>
          </DragAndDrop>
          <FolderModal onCancel={handleFolderCancel} onSubmit={handleFolderSubmit} open={Boolean(newFolderParentId)} />

          {showExtInstallationModal && <ExtInstallationModal setOpen={setShowExtInstallationModal} open={showExtInstallationModal} />}

          <ShareModal loading={shareProps.loading} onOk={handleShareModalOk} url={shareProps.url} />

          {user && <GamificationSetGoalPrompExperimentModal />}
        </>
      )}
    </>
  );
};

const FeedbackRenderer = ({ setShowingFeedback }: { setShowingFeedback: (show: boolean) => void }) => {
  const listeningExperienceVariant = useFeatureVariant(FeatureNameEnum.WEB_APP_LISTENING_EXPERIENCE_OVERHAUL);
  const { FeedbackLibraryExperience, showingLibraryFeedback } = useFeedback();

  const [showingV2, setShowingV2] = useState(true);
  const wordCountV2 = Number.parseInt(localStorage.getItem('currentWordCount') ?? '0', 10);
  const feedbackShownAlreadyV2 = localStorage.getItem(CSAT_SHOWN_STORAGE_KEY);

  const showFeedbackV2 = showingV2 && !feedbackShownAlreadyV2 && wordCountV2 > 500;

  const onRatingSubmit = useCallback((value: number) => {
    logAnalyticsEvent(AnalyticsEventKey.listeningFeedbackRating, { sentiment: value, isRehaul: true });
  }, []);

  const onFeedbackSubmit = useCallback((value: number, feedback: string) => {
    logAnalyticsEvent(AnalyticsEventKey.listeningFeedbackContent, { feedback: feedback, sentiment: value, isRehaul: true });
  }, []);

  useEffect(() => {
    // this is done this way for compatibiliy reasons with other banners
    // This will be removed with test being concluded.
    if (!isFeatureVariantReady(listeningExperienceVariant)) return;
    if (listeningExperienceVariant.variant === WebAppListeningExperienceOverhaulVariant.CONTROL) {
      setShowingFeedback(showingLibraryFeedback);
    }

    if (listeningExperienceVariant.variant === WebAppListeningExperienceOverhaulVariant.ENABLED) {
      setShowingFeedback(showFeedbackV2);
    }
  }, [showingLibraryFeedback, setShowingFeedback, listeningExperienceVariant, showFeedbackV2]);

  if (!isFeatureVariantReady(listeningExperienceVariant)) return null;

  if (listeningExperienceVariant.variant === WebAppListeningExperienceOverhaulVariant.CONTROL) {
    return <FeedbackLibraryExperience />;
  }

  if (listeningExperienceVariant.variant === WebAppListeningExperienceOverhaulVariant.ENABLED && showFeedbackV2) {
    return (
      <div className="mb-spl-11">
        <CSATPopup onFeedbackSubmit={onFeedbackSubmit} onRatingSubmit={onRatingSubmit} onDismiss={() => setShowingV2(false)} />
      </div>
    );
  }
};

const InstantListeningRenderer = ({
  instantListening,
  newlyImportedItemId
}: {
  instantListening: InstantListening;
  newlyImportedItemId: string | null | undefined;
}) => {
  const dispatch = useDispatch();
  const { autoScroll, setAutoScroll } = useAutoScroll();
  const { value: highlighting, updateValue: setHighlighting } = usePreferenceVariable('highlighting', true);
  const { value: hideControls, updateValue: setHideControls } = usePreferenceVariable('hidecontrols', false);
  const { matches: isDesktop } = useMediaQuery('(min-width: 768px)');

  const instantListeningSettings = useMemo(
    () => ({
      autoScroll,
      hideControls,
      highlighting
    }),
    // ESLint: React Hook useMemo has a missing dependency: 'autoScroll'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideControls, highlighting]
  );

  const instantListeningReader = useMemo(
    () => ({
      setAutoScroll,
      setHideControls,
      setHighlighting
    }),
    [setAutoScroll, setHideControls, setHighlighting]
  );

  const onBackArrowClick = useCallback(() => {
    dispatch(mainPageActions.setInstantListening(null));
    replaceUrlWithoutAnyEffect('/');
  }, [dispatch]);

  if (isInstantListeningV1(instantListening)) {
    return (
      <div className="min-h-screen bg-glass-200" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
        {!isInstantListeningV1Loaded(instantListening) ? (
          <LoadingSkeleton />
        ) : (
          <>
            <Header
              isInstantListening
              back
              newlyImportedItemId={newlyImportedItemId!}
              search={false}
              title={instantListening.details.readingInfo.thumbnail.title!}
            />

            <Sidebar
              isInstantListening
              isPlaybackPaused={instantListening.details.readingInfo.playbackControls.playPauseButton === PlayPauseButton.ShowPlay}
              itemId={newlyImportedItemId!}
              readingInfo={instantListening.details.readingInfo}
              reader={instantListeningReader}
            />
            {isDesktop && <AutoScrollButton />}
            {isBook(instantListening.details.readingInfo) && (
              <SDKBookReader
                flowStartTimestamp={instantListening.flowStartTimestamp}
                readingInfo={instantListening.details.readingInfo}
                {...instantListeningSettings}
                {...instantListeningReader}
                itemId={newlyImportedItemId!}
              />
            )}
            {isClassic(instantListening.details.readingInfo) && (
              <SDKClassicReader
                showArticleTitle={true}
                readingInfo={instantListening.details.readingInfo}
                flowStartTimestamp={instantListening.flowStartTimestamp}
                {...instantListeningSettings}
                {...instantListeningReader}
              />
            )}
          </>
        )}
      </div>
    );
  }
  if (isInstantListeningV2Loaded(instantListening)) {
    return <ListeningExperienceV2 listenableContent={instantListening.details.listenableContent} onBackArrowClick={onBackArrowClick} />;
  }
  return <LoadingSkeleton />;
};

export default withAuth(DashboardPage);
