import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';
import { IntegrationService } from 'interfaces/integrations';
import { auth } from 'lib/speechify';
import { intersection } from 'lodash';
import { useDispatch, useSelector } from 'store';
import { actions as importActions, selectors as importSelectors } from 'store/import';
import { actions as integrationActions } from 'store/integration';
import { twMerge } from 'tailwind-merge';

import { XIcon } from '@heroicons/react/outline';

import { IntegrationIcons, IntegrationTitles } from './constants';
import { FeatureNameEnum, isFeatureVariantReady, useFeatureVariant } from 'hooks/useFeatureFlags';
import { CanvasIntegrationTestVariant, OneDriveIntegrationTestVariant } from 'constants/featureDefinitions';
import { useInterval } from 'hooks/useInterval';

function IntegrationItem({ service }: { service: IntegrationService }) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const showTooltip = useSelector(state => state.integration.showTooltip?.[service]);
  const { isModalOpen } = useSelector(importSelectors.getImportState);
  const Icon = IntegrationIcons[service];

  const handleTooltipClose = useCallback(() => {
    dispatch(integrationActions.hideTooltip(service));
  }, [service, dispatch]);

  return (
    <div className="relative" onClick={handleTooltipClose}>
      <button
        className={twMerge(
          'w-full flex items-center gap-2 py-2 px-3.5 cursor-pointer rounded-[10px]',
          'hover:bg-glass-hovered-300 active:bg-glass-pressed-300 transition-colors duration-75'
        )}
        onClick={() => dispatch(importActions.openImportDialog({ source: service }))}
      >
        <Icon className="w-5 h-5" />
        <span className="text-glass-700 font-medium font-ABCDiatype text-sm">{IntegrationTitles[service].name}</span>
      </button>
      {showTooltip && !isModalOpen && (
        <div
          className={twMerge(
            'rounded-md bg-glass-700 text-glass-0 shadow-500 py-3 pl-4 pr-6 box-border w-64',
            'absolute z-[100] left-full top-1/2 -translate-y-1/2 translate-x-[8px]',
            "after:content-[''] after:border after:border-transparent after:border-r-glass-700 after:border-r-[8px] after:border-y-[6px] after:block after:h-0 after:w-0",
            'after:absolute after:-left-[8px] after:top-1/2 after:-translate-y-1/2'
          )}
        >
          <XIcon className="absolute right-1 top-1 float-right block h-5 w-5 cursor-pointer text-glass-350" aria-hidden="true" onClick={handleTooltipClose} />
          <p className="text-base font-bold mb-1">✨ {t('New integration added')}</p>
          <p className="text-sm">{t('Click to import and listen to your files with Speechify.')}</p>
        </div>
      )}
    </div>
  );
}

function EnabledIntegrations({ children }: PropsWithChildren): JSX.Element {
  const dispatch = useDispatch();
  const auths = useSelector(state => state.integration.auths);
  const user = useSelector(state => state.auth.user);
  const userUid = auth.currentUser?.uid;

  const [isTabVisible, setIsTabVisible] = useState(!document.hidden);

  const canvasIntegrationVariant = useFeatureVariant(FeatureNameEnum.CANVAS_INTEGRATION);
  const isCanvasIntegrationEnabled =
    isFeatureVariantReady(canvasIntegrationVariant) && canvasIntegrationVariant.variant === CanvasIntegrationTestVariant.ENABLED;
  const oneDriveIntegrationVariant = useFeatureVariant(FeatureNameEnum.ONE_DRIVE_INTEGRATION);
  const isOneDriveIntegrationEnabled =
    isFeatureVariantReady(oneDriveIntegrationVariant) && oneDriveIntegrationVariant.variant === OneDriveIntegrationTestVariant.ENABLED;

  const enabledIntegrations = useMemo(() => {
    const services = Object.entries(auths)
      .filter(([service, { authorized }]) => {
        if (!authorized) return false;
        if (service === IntegrationService.CANVAS && !isCanvasIntegrationEnabled) return false;
        if (service === IntegrationService.ONE_DRIVE && !isOneDriveIntegrationEnabled) return false;
        return true;
      })
      .map(([service]) => service);

    return intersection(Object.values(IntegrationService), services as IntegrationService[]);
  }, [auths, isCanvasIntegrationEnabled, isOneDriveIntegrationEnabled]);

  useEffect(() => {
    // Need to check auth.currentUser?.uid as well because we need to grab user token when fetching integrations
    if (user?.uid && userUid) {
      dispatch(integrationActions.fetchAuths());
    }
  }, [dispatch, user?.uid, userUid]);

  useEffect(() => {
    const visibilityChangeHandler = () => setIsTabVisible(!document.hidden);
    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => document.removeEventListener('visibilitychange', visibilityChangeHandler);
  }, []);

  useInterval(
    () => {
      dispatch(integrationActions.fetchAuths());
    },
    isTabVisible ? 120_000 : null
  );

  if (!enabledIntegrations.length) return <>{children}</>;

  return (
    <div className="bg-glass-300 p-2 pt-1 mt-[-4px] mb-3 rounded-xl">
      {children}
      <div className="flex flex-col w-full gap-1">
        {enabledIntegrations.map(service => (
          <IntegrationItem service={service} key={service} />
        ))}
      </div>
    </div>
  );
}

export default React.memo(EnabledIntegrations);
